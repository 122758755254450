import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import { observer } from 'mobx-react';
import CheckInOutReservation from "./CheckInOutReservation";
import HotelReservationUpdateStateModal from "../reservation/HotelReservationUpdateStateModal";
import {
  GetCheckInOut,
  GetCheckInOutVariables,
  GetHotels_hotels_data,
  HotelReservation
} from "../../../../types/GensonGRM";
import Modal from "../../../modal/Modal";
import {gql, useLazyQuery, useQuery} from "@apollo/client";
import { HotelReservation as HotelReservationFragment } from "../../../../store/apollo/fragments/Fragments";
import { utils, writeFile } from "xlsx";
import {StoreContext} from "../../../../store/StoreContext";
import getDateFromWeekNumber from "../../../../util/getDateFromWeekNumber";
import getWeekNumber from "../../../../util/getWeekNumber";

const MILLIS_WEEK = 1000 * 60 * 60 * 24 * 7;

const FORMAT_DATE = (date: Date) => {
  const y = date.getFullYear();
  const m = date.getMonth() + 1;
  const d = date.getDate();

  return `${y}-${m}-${d}`;
}

const GENERATE_EXCEL = (
  checkIns: HotelReservation[],
  checkOuts: HotelReservation[],
  checkedIns: HotelReservation[],
  checkedOuts: HotelReservation[],
) => {
  const map = (r: HotelReservation, inOrOut: boolean) => {
    const inOut = inOrOut
      ? {
        'Ingecheckt': r.inProgressAt ? 'Ja' : 'Nee',
        'Check-in datum': r.inProgressAt
          ? new Date(r.inProgressAt).toLocaleString('nl', { timeZone: 'Europe/Amsterdam' }).replace(',', '')
          : '',
      }
      : {
        'Uitgecheckt': r.completedAt ? 'Ja' : 'Nee',
        'Check-out datum': r.completedAt
          ?  new Date(r.completedAt).toLocaleString('nl', { timeZone: 'Europe/Amsterdam' }).replace(',', '')
          : '',
      };

    return {
      'Naam': r.employee.name,
      'Nummer': r.employee.number,
      'Nationaliteit': r.employee.nationality,
      'Hotel': r.bed.room.section.hotel.name,
      'Sectie': r.bed.room.section.name,
      'Kamer': r.bed.room.name,
      'Start': new Date(r.startAt).toLocaleString('nl', { timeZone: 'Europe/Amsterdam' }).split(',')[0],
      'Eind': r.endAt ? new Date(r.endAt).toLocaleString('nl', { timeZone: 'Europe/Amsterdam' }).split(',')[0] : "",
      ...inOut,
    };
  };

  const dataCheckIn = [...checkIns, ...checkedIns].map(r => map(r, true));
  const dataCheckOut = [...checkOuts, ...checkedOuts].map(r => map(r, false));

  const wb = utils.book_new();

  const sheetCheckIn = utils.json_to_sheet(dataCheckIn);
  sheetCheckIn['!cols'] = [
    { width: 26 }, { width: 9 }, { width: 11 },
    { width: 15 }, { width: 15 }, { width: 15 },
    { width: 10 }, { width: 10 }, { width: 10 },
    { width: 18 }
  ];
  utils.book_append_sheet(wb, sheetCheckIn, 'Check-in');

  const sheetCheckOut = utils.json_to_sheet(dataCheckOut);
  sheetCheckOut['!cols'] = [
    { width: 26 }, { width: 9 }, { width: 11 },
    { width: 15 }, { width: 15 }, { width: 15 },
    { width: 10 }, { width: 10 }, { width: 10 },
    { width: 18 }
  ];
  utils.book_append_sheet(wb, sheetCheckOut, 'Check-out');

  writeFile(wb, `checkin-checkout.xlsx`);
}

export const GET_CHECK_IN_OUT = gql`
  query GetCheckInOut($today: Date!, $date: Date!, $future: Date!, $hotel: ID) {
    checkIn: hotelReservations(first: 100, startUntil: $date, hotel: $hotel, state: PLANNED) {
      data {
        ...HotelReservation
      }
    }

    checkOut: hotelReservations(first: 100, endUntil: $date, hasEnd: true, hotel: $hotel, state: IN_PROGRESS) {
      data {
        ...HotelReservation
      }
    }
    
    futureCheckIn: hotelReservations(first: 100, startUntil: $today, hotel: $hotel, state: PLANNED) {
      data {
        ...HotelReservation
      }
    }

    futureCheckOut: hotelReservations(first: 100, endFrom: $today, endUntil: $future, hasEnd: true, hotel: $hotel, state: IN_PROGRESS) {
      data {
        ...HotelReservation
      }
    }
    
    checkedIn: hotelReservations(first: 100, inProgressFrom: $today, inProgressUntil: $future, hotel: $hotel, state: IN_PROGRESS) {
      data {
        ...HotelReservation
      }
    }
    
    checkedOut: hotelReservations(first: 100, completedFrom: $today, completedUntil: $future, hotel: $hotel, state: COMPLETED) {
      data {
        ...HotelReservation
      }
    }
  }

  ${HotelReservationFragment}
`;

export interface CheckInOutOverviewProps {
  hotel?: GetHotels_hotels_data;
  executeExportOverview?: boolean;
  executeExportToday?: boolean;
  includesHistory: boolean;
}

const CheckInOutOverview: React.FC<CheckInOutOverviewProps> = observer((props) => {
  const store = useContext(StoreContext);
  const refReservationUpdateModal = useRef<Modal>(null);
  const [reservation, setReservation] = useState<HotelReservation | undefined>(undefined);

  const now = useMemo(() => {
    const now = new Date();
    const [year, number] = getWeekNumber(now);
    return getDateFromWeekNumber(store.housingYear ?? year, store.housingWeek ?? number);
  }, [store.housingWeek, store.housingYear]);

  const variables = useMemo(() => {
    return {
      date: FORMAT_DATE(new Date()),
      today: FORMAT_DATE(now),
      future: FORMAT_DATE(new Date(now.getTime() + MILLIS_WEEK)),
      hotel: props.hotel?.id
    };
  }, [now, props.hotel?.id]);

  const checkInOut = useQuery<GetCheckInOut, GetCheckInOutVariables>(GET_CHECK_IN_OUT, {
    variables,
    fetchPolicy: 'no-cache',
  });

  const [loadExportData, exportCheckInOut] = useLazyQuery<GetCheckInOut, GetCheckInOutVariables>(GET_CHECK_IN_OUT, {
    fetchPolicy: 'no-cache',
  });

  const checkIns = useMemo(() => {
    const res = [
      ...checkInOut.data?.checkIn?.data || [],
      ...checkInOut.data?.futureCheckIn?.data || [],
    ];

    return [...new Map(res.map(item => [item.id, item])).values()];
  }, [checkInOut.data]);

  const checkOuts = useMemo(() => {
    const res = [
      ...checkInOut.data?.checkOut?.data || [],
      ...checkInOut.data?.futureCheckOut?.data || [],
    ];

    return [...new Map(res.map(item => [item.id, item])).values()];
  }, [checkInOut.data]);

  useEffect(() => {
    if (props.executeExportToday !== true) return;
    if (!exportCheckInOut.data) {
      const n = new Date();
      const f = FORMAT_DATE(n);
      return loadExportData({
        variables: {
          date: f,
          today: f,
          future: FORMAT_DATE(new Date(n.getTime() + MILLIS_WEEK)),
          hotel: props.hotel?.id,
        },
      });
    }

    const cIns = [
      ...exportCheckInOut.data?.checkIn?.data || [],
      ...exportCheckInOut.data?.futureCheckIn?.data || [],
    ];

    const cOuts = [
      ...exportCheckInOut.data?.checkOut?.data || [],
      ...exportCheckInOut.data?.futureCheckOut?.data || [],
    ];

    GENERATE_EXCEL(
      [...new Map(cIns.map(item => [item.id, item])).values()],
      [...new Map(cOuts.map(item => [item.id, item])).values()],
      exportCheckInOut.data?.checkedIn?.data ?? [],
      exportCheckInOut.data?.checkedOut?.data ?? [],
    );
  }, [
    exportCheckInOut.data,
    loadExportData,
    props.executeExportToday,
    props.hotel?.id,
  ]);

  // Export the employee lists when asked from parent component.
  useEffect(() => {
    if (props.executeExportOverview !== true) return;
    if (!checkInOut.data) return;

    GENERATE_EXCEL(
      checkIns,
      checkOuts,
      checkInOut.data?.checkedIn?.data ?? [],
      checkInOut.data?.checkedOut?.data ?? [],
    );
  }, [checkInOut.data, checkIns, checkOuts, props.executeExportOverview]);

  if (checkInOut.loading) {
    return (
      <div>Loading...</div>
    );
  }

  if (checkInOut.error || !checkInOut.data?.checkIn?.data || !checkInOut.data?.checkOut?.data) {
    return (
      <div>Er is iets misgegaan, probeer het nogmaals.</div>
    );
  }

  return (
    <section className="check-in-out-overview">
      <div className="row">
        <div className="col">
          <small>Check-ins</small>
          <ul className="reservations">
            {checkIns.map(r => (
              <CheckInOutReservation key={r.id} reservation={r} onAction={() => {
                setReservation(r);
                refReservationUpdateModal.current?.open();
              }}/>
            ))}

            {checkIns.length < 1 && (
              <i>Er zijn momenteel geen check-in reserveringen</i>
            )}
          </ul>
        </div>

        <div className="col">
          <small>Check-outs</small>
          <ul className="reservations">
            {checkOuts
              .filter(r => r.endAt)
              .map(r => (
                <CheckInOutReservation key={r.id} reservation={r} onAction={() => {
                  setReservation(r);
                  refReservationUpdateModal.current?.open();
                }}/>
              ))}

            {checkOuts.length < 1 && (
              <i>Er zijn momenteel geen check-out reserveringen</i>
            )}
          </ul>
        </div>
      </div>

      {props.includesHistory && (
        <div className="row">
          <div className="col">
            <small>Reeds ingecheckt</small>
            <ul className="reservations">
              {checkInOut.data.checkedIn?.data.map(r => (
                <CheckInOutReservation key={r.id} reservation={r}/>
              ))}

              {!checkInOut.data.checkedIn?.data.length && (
                <i>Er zijn momenteel geen reeds ingecheckte reserveringen</i>
              )}
            </ul>
          </div>

          <div className="col">
            <small>Reeds uitgecheckt</small>
            <ul className="reservations">
              {checkInOut.data.checkedOut?.data.map(r => (
                <CheckInOutReservation key={r.id} reservation={r}/>
              ))}

              {!checkInOut.data.checkedOut?.data.length && (
                <i>Er zijn momenteel geen reeds uitgecheckte reserveringen</i>
              )}
            </ul>
          </div>
        </div>
      )}

      <HotelReservationUpdateStateModal ref={refReservationUpdateModal} reservation={reservation}/>
    </section>
  )
});

export default CheckInOutOverview;


