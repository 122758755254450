import React, { useContext, useEffect, useState } from 'react';
import UserMenu from '../../components/user/UserMenu';
import CheckInOutOverview from "../../components/housing/hotel/checkinout/CheckInOutOverview";
import HotelPicker from '../../components/housing/hotel/HotelPicker';
import { StoreContext } from '../../store/StoreContext';
import { GetHotels_hotels_data } from '../../types/GensonGRM';
import YearPicker from "../../components/housing/hotel/YearPicker";
import WeekPicker from "../../components/housing/hotel/WeekPicker";

const CheckInOut: React.FC = () => {
  const store = useContext(StoreContext);
  const [hotel, setHotel] = useState<GetHotels_hotels_data | undefined>(store.hotel ?? undefined);
  const [executeExportOverview, setExecuteExportOverview] = useState<boolean>(false);
  const [executeExportToday, setExecuteExportToday] = useState<boolean>(false);
  const [includesHistory, setIncludesHistory] = useState<boolean>(false);

  // Automatically reset the 'execute export' boolean.
  useEffect(() => {
    if (executeExportOverview) {
      setExecuteExportOverview(false);
    }
  }, [executeExportOverview]);

  return (
    <section className="view-check-in-out">
      <div className="row align-items-center page-header">
        <div className="col-auto">
          <HotelPicker ignoreSection allowEmptySelection onHotel={(h) => setHotel(h)}/>
        </div>

        <div className="col-auto">
          <YearPicker/>
        </div>

        <div className="col-auto">
          <WeekPicker/>
        </div>

        <div className="col"/>

        <div className="col-auto">
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="includesHistorySwitch"
              checked={includesHistory}
              onChange={(e) =>
                setIncludesHistory(e.target.checked)}
            />

            <label className="form-check-label" htmlFor="includesHistorySwitch">
              Inclusief reeds in/uitgecheckt.
            </label>
          </div>
        </div>

        <div className="col-auto">
          <button className="btn btn-import" onClick={() => setExecuteExportOverview(true)}>
            Exporteren Week
          </button>
        </div>

        <div className="col-auto">
          <button className="btn btn-import" onClick={() => setExecuteExportToday(true)}>
            Exporteren Vandaag
          </button>
        </div>

        <div className="col-auto">
          <UserMenu/>
        </div>
      </div>

      <CheckInOutOverview
        hotel={hotel}
        includesHistory={includesHistory}
        executeExportOverview={executeExportOverview}
        executeExportToday={executeExportToday}
      />
    </section>
  )
};

export default CheckInOut;
