import React, { useContext, useEffect, useRef, useState } from 'react';
import { gql, useMutation, useQuery } from "@apollo/client";
import { observer } from 'mobx-react';
import { StoreContext } from '../../../store/StoreContext';
import { CreateHotel, CreateHotelSection, CreateHotelSectionVariables, CreateHotelVariables, GetHotels, GetHotelsVariables, GetHotels_hotels_data, GetHotels_hotels_data_sections } from '../../../types/GensonGRM';
import HotelModal from './HotelModal';
import { Hotel, HotelSection } from '../../../store/apollo/fragments/Fragments';
import HotelSectionModal from './HotelSectionModal';

export interface HotelPickerProps {
  allowEmptySelection?: boolean;
  allowCreation?: boolean;
  ignoreSection?: boolean;

  onHotel?: (hotel: GetHotels_hotels_data | undefined) => void;
  onSection?: (section: GetHotels_hotels_data_sections | undefined) => void;
}

export const GET_HOTELS = gql`
  query GetHotels($page: Int!) {
    hotels(first: 12, page: $page) {
      paginatorInfo {
        total
        lastPage
        currentPage
      }
      data {
        ...Hotel
      }
    }
  }
  
  ${Hotel}
`;

export const CREATE_HOTEL = gql`
  mutation CreateHotel($input: HotelInput!) {
    createHotel(input: $input) {
      ...Hotel
    }
  }
  
  ${Hotel}
`;

export const CREATE_HOTEL_SECTION = gql`
  mutation CreateHotelSection($input: HotelSectionInput!) {
    createHotelSection(input: $input) {
      ...HotelSection
    }
  }
  
  ${HotelSection}
`;

const HotelPicker: React.FC<HotelPickerProps> = observer((props) => {
  const store = useContext(StoreContext);

  const { loading, error, data } = useQuery<GetHotels, GetHotelsVariables>(GET_HOTELS, {
    variables: {
      page: 1
    },
  });
  const [createHotel] = useMutation<CreateHotel, CreateHotelVariables>(CREATE_HOTEL);
  const [createHotelSection] = useMutation<CreateHotelSection, CreateHotelSectionVariables>(CREATE_HOTEL_SECTION);

  const [hotels, setHotels] = useState<GetHotels_hotels_data[] | undefined>(store.hotels);
  const [hotel, setHotel] = useState<string>(store.hotel?.id ?? "-1");
  const [sections, setSections] = useState<GetHotels_hotels_data_sections[] | undefined>(store.hotel?.sections);
  const [section, setSection] = useState<string>(store.hotelSection?.id ?? "-1");

  const refHotelModal = useRef<HotelModal | null>(null);
  const refHotelSectionModal = useRef<HotelSectionModal | null>(null);

  useEffect(() => {
    if (!data?.hotels || !data.hotels.data) return;
    setHotels(data.hotels.data);

    if (hotel) return;
    setHotel(data.hotels.data[0].id);
  }, [data, hotel]);

  useEffect(() => {
    store.setHotels(hotels ?? []);
  }, [store, hotels]);

  useEffect(() => {
    if (!data) return;

    const selectedHotel = hotels?.find(h => h.id === hotel);
    setSections(selectedHotel?.sections);

    if (store.hotel?.id !== selectedHotel?.id) {
      store.setHotel(selectedHotel ?? null);

      setSection(
        !props.allowEmptySelection &&
          selectedHotel &&
          selectedHotel.sections &&
          selectedHotel.sections.length > 0
          ? selectedHotel?.sections[0].id
          : "-1"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, hotel, hotels]);

  useEffect(() => {
    const selectedHotel = hotels?.find(s => s.id === hotel);
    if (props.onHotel) props.onHotel(selectedHotel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotel]);

  useEffect(() => {
    const selectedSection = sections?.find(s => s.id === section);
    store.setHotelSection(selectedSection ?? null);

    if (props.onSection) props.onSection(selectedSection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, section, sections])

  if (loading) return <div />;
  if (error || !data || !data.hotels) return <div />;

  return (
    <div className="row hotel-picker">
      <div className="col-auto">
        <label>
          Hotel
        </label>

        <select
          className="form-select"
          aria-label="Hotel selectie"
          value={hotel || '-1'}
          onChange={e => {
            setHotel(e.target.value);
          }}>
          <option value={"-1"} disabled={!props.allowEmptySelection}>
            {props.allowEmptySelection ? "Alle hotels" : "Selecteer een hotel"}
          </option>

          {hotels?.map(hotel => (
            <option key={hotel.id} value={hotel.id}>{hotel.name}</option>
          ))}
        </select>
        {props.allowCreation && store.user?.can('housing-edit') && (
          <button className="add"
            onClick={() => refHotelModal.current?.open()}
          >
            <span className="material-icons">add</span>
          </button>)}
      </div>

      {!props.ignoreSection && (<div className="col-auto">
        <label>
          Sectie
        </label>

        <select
          className="form-select"
          aria-label="Locatie selectie"
          value={section || '-1'}
          disabled={!hotel && !props.allowEmptySelection}
          onChange={e => {
            setSection(e.target.value);
          }}>
          <option value={"-1"} disabled={!props.allowEmptySelection}>
            {props.allowEmptySelection ? "Alle secties" : "Selecteer een sectie"}
          </option>

          {sections && sections.map(section => (
            <option key={section.id} value={section.id}>{section.name}</option>
          ))}
        </select>
        {props.allowCreation && hotel && store.user?.can('housing-edit') &&
          <button className="add"
            onClick={() => refHotelSectionModal.current?.open()}
          >
            <span className="material-icons">add</span>
          </button>}
      </div>)}

      <HotelModal
        ref={refHotelModal}
        onSave={async (name, updatedHotel) => {
          if (updatedHotel) return;

          const { data } = await createHotel({
            variables: {
              input: {
                name: name
              }
            },
            refetchQueries: [{
              query: GET_HOTELS,
              variables: {
                page: 1
              }
            }],
            awaitRefetchQueries: true
          });

          if (!data?.createHotel) {
            return
          }

          const hotelsList = hotels?.concat([data.createHotel]);
          if (!hotelsList) return;
          setHotels(hotelsList);
          setHotel(data.createHotel.id);
        }}
      />

      <HotelSectionModal
        ref={refHotelSectionModal}
        hotel={store.hotel ?? undefined}
        onSave={async (name, updatedHotelSection) => {
          if (updatedHotelSection) return;
          if (!store.hotel) return;

          const { data } = await createHotelSection({
            variables: {
              input: {
                name: name,
                hotel: {
                  connect: store.hotel.id
                }
              }
            },
            refetchQueries: [{
              query: GET_HOTELS,
              variables: {
                page: 1
              }
            }],
            awaitRefetchQueries: true
          });

          if (!data?.createHotelSection) {
            return;
          }

          const sectionsList = sections?.concat([data.createHotelSection]);
          if (!sectionsList) return;
          setSections(sectionsList);
          setSection(data.createHotelSection.id);
        }} />
    </div>
  );
});

export default HotelPicker;
