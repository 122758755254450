import { gql, useMutation } from '@apollo/client';
import React from 'react';
import { GetHotels_hotels_data_sections, DeleteHotelSection, DeleteHotelSectionVariables } from '../../../../types/GensonGRM';
import Modal from '../../../modal/Modal';
import { GET_HOTELS } from '../HotelPicker';

export interface HotelSectionDeleteModalProps {
  hotelSection: GetHotels_hotels_data_sections;
}

export const DELETE_HOTEL_SECTION = gql`
  mutation DeleteHotelSection($id: ID!) {
    deleteHotelSection(id: $id) {
      id
    }
  }
`;

const HotelSectionDeleteModal = React.forwardRef<Modal, HotelSectionDeleteModalProps>((props, ref) => {
  const [deleteHotelSection] = useMutation<DeleteHotelSection, DeleteHotelSectionVariables>(DELETE_HOTEL_SECTION);

  return (
    <Modal ref={ref} 
      title="Hotel verwijderen"
      button="Hotel verwijderen"
      buttonIsDanger={true}
      valid={true}
      onSubmit={async () => {
        await deleteHotelSection({
          variables: {
            id: props.hotelSection.id
          },
          refetchQueries: [{
            query: GET_HOTELS,
            variables: {
              page: 1
            }
          }],
          awaitRefetchQueries: true
        });
        
        return true;
      }}
      >
      <div className="row">
        <div className="col">
          Weet je zeker dat je hotel sectie '{props.hotelSection.name}' wilt verwijderen?
        </div>
      </div>
    </Modal>
  )
});

export default HotelSectionDeleteModal;
