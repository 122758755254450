import React, { useContext } from 'react';
import { StoreContext } from '../../../store/StoreContext';
import {
  GetHotels_hotels_data_sections
} from '../../../types/GensonGRM';
import Dropdown from '../../dropdown/Dropdown';

export interface WeekContextProps {
  hotelSection: GetHotels_hotels_data_sections;

  onUpdateHotel: () => void;
  onDeleteHotel: () => void;
  onUpdateHotelSection: () => void;
  onDeleteHotelSection: () => void;
}

const WeekContext: React.FC<WeekContextProps> = (props) => {
  const store = useContext(StoreContext);

  let dropdownItems = [];

  if (store.user?.can('housing-edit')) {
    dropdownItems.push({
      title: 'Hotel aanpassen',
      disabled: false,
      action: () => props.onUpdateHotel(),
    });
    
    dropdownItems.push({
      title: 'Hotel verwijderen',
      disabled: false,
      action: () => props.onDeleteHotel(),
    });
    
    dropdownItems.push({
      title: 'Hotel sectie aanpassen',
      disabled: false,
      action: () => props.onUpdateHotelSection(),
    });

    dropdownItems.push({
      title: 'Hotel sectie verwijderen',
      disabled: false,
      action: () => props.onDeleteHotelSection(),
    });
  }

  return (
    <Dropdown
      items={dropdownItems}
    />
  );
};

export default WeekContext;
