import React, { useContext } from 'react';
import { StoreContext } from '../../../../store/StoreContext';
import { GetHotelSection_hotelSection_rooms_beds_reservations_data, HotelReservation, HotelReservationState } from '../../../../types/GensonGRM';
import Dropdown from '../../../dropdown/Dropdown';

export interface ReservationContextProps {
  reservation: GetHotelSection_hotelSection_rooms_beds_reservations_data | HotelReservation;

  onAlterReservation: () => void;
  onDeleteReservation: () => void;
}

const ReservationContext: React.FC<ReservationContextProps> = (props) => {
  const store = useContext(StoreContext);

  let dropdownItems = [];

  if (props.reservation && props.reservation.state !== HotelReservationState.COMPLETED && store.user?.can('housing-reservations')) {
    dropdownItems.push({
      title: 'Reservering aanpassen',
      disabled: false,
      action: props.onAlterReservation,
    });
  }

  if (props.reservation && props.reservation.state === HotelReservationState.PLANNED && store.user?.can('housing-reservations')) {
    dropdownItems.push({
      title: 'Reservering verwijderen',
      disabled: false,
      action: props.onDeleteReservation,
    });
  }

  return (
    <Dropdown
      items={dropdownItems}
    />
  );
};

export default ReservationContext;
